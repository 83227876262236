<template>
  <div id="app">
    <GlobalLoader />
    <router-view />
  </div>
</template>

<script>
import GlobalLoader from './components/Utils/GlobalLoader.vue';

export default {
  components: {
    GlobalLoader,
  },
};
</script>

<style>

</style>
