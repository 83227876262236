<template>
  <teleport to="body">
    <div class="modal-overlay">
      <div class="modal-content-container">
        <div class="modal-title">
          Demandez de l'aide
        </div>

        <div class="modal-content">
          <!-- Ici, incluez le contenu dynamique, par exemple : -->
          <div
            v-html="service.access_request_description ?? service.description"
          />

          <!-- Formulaire -->
          <form
            class="flex-1 p-2"
            @submit.prevent="submitForm"
          >
            <div class="form-service ml-2 mt-2 mb-1">
              <label for="serviceRequestOption">Nature*</label>
              <select
                id="serviceRequestOption"
                v-model="form.serviceRequestOption"
                required
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Sélectionnez une option
                </option>
                <option
                  v-for="option in service.access_request_options"
                  :key="option.label"
                  :value="option.label"
                >
                  {{ option.label }}
                </option>
              </select>
              <div
                v-if="errors.serviceRequestOption"
                class="list-style"
              >
                {{ errors.serviceRequestOption }}
              </div>
            </div>

            <div class="form-service ml-2 mt-2 mb-1">
              <label for="serviceRequestComment">Commentaire</label>
              <textarea
                id="serviceRequestComment"
                v-model="form.serviceRequestComment"
                class="p-0-5"
                required
              />
              <div
                v-if="errors.serviceRequestComment"
                class="list-style"
              >
                {{ errors.serviceRequestComment }}
              </div>
            </div>

            <div class="form-service ml-2 mt-2 mb-1">
              <label for="serviceRequestPhoneNumber">Numéro de téléphone</label>
              <input
                id="serviceRequestPhoneNumber"
                v-model="form.serviceRequestPhoneNumber"
                class="p-0-5"
                required
              >
              <div
                v-if="errors.serviceRequestPhoneNumber"
                class="list-style"
              >
                {{ errors.serviceRequestPhoneNumber }}
              </div>
            </div>
          </form>
        </div>

        <div class="modal-actions">
          <button
            class="button-home-redirection p-1 mr-2"
            @click="cancel"
          >
            Annuler
          </button>
          <button
            class="button-home-redirection p-1"
            @click="submitForm"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { sendRequestServiceTypeForm } from '../../../services/api.js';

export default {
  props: ['service'],
  emits: ['close-form-modal', 'request-form-validated'],
  data() {
    return {
      form: {
        serviceRequestOption: '',
        serviceRequestComment: '',
        serviceRequestPhoneNumber: '',
      },
      errors: {},
    };
  },
  methods: {
    cancel() {
      this.$emit('close-form-modal');
    },
    validateForm() {
      this.errors = {};
      let isValid = true;

      if (!this.form.serviceRequestOption) {
        this.errors.serviceRequestOption = 'Ce champ est obligatoire.';
        isValid = false;
      }

      if (!this.form.serviceRequestComment) {
        this.errors.serviceRequestComment = 'Ce champ est obligatoire.';
        isValid = false;
      }

      if (!this.form.serviceRequestPhoneNumber) {
        this.errors.serviceRequestPhoneNumber = 'Ce champ est obligatoire.';
        isValid = false;
      }

      return isValid;
    },
    async submitForm() {
      if (this.validateForm()) {
        try {
          const formData = {
            option: this.form.serviceRequestOption,
            comment: this.form.serviceRequestComment,
            phone_number: this.form.serviceRequestPhoneNumber,
          };

          const response = await sendRequestServiceTypeForm(
            this.service.id,
            formData,
          );

          const toast = useToast();
          if (response.data.success) {
            toast.success('Formulaire envoyé avec succès !');
            this.$emit('request-form-validated');
            this.$emit('close-form-modal');
          } else {
            toast.error("Erreur lors de l'envoi du formulaire.");
          }
        } catch (error) {
          console.error('Erreur lors de la soumission du formulaire:', error);
          const toast = useToast();
          toast.error("Erreur lors de l'envoi du formulaire.");
        }
      }
    },
  },
};
</script>

<style scoped>
.form-service {
  margin-bottom: 1.5rem;
}

.form-service label {
  display: block;
  margin-bottom: 0.5rem;
  color: #052f5f;
  font-weight: bold;
}

.form-service input,
.form-service select,
.form-service textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-service input:focus,
.form-service select:focus,
.form-service textarea:focus {
  border-color: #052f5f;
  outline: none;
}

.list-style {
  color: #d9534f;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
</style>
