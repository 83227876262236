<template>
  <teleport to="body">
    <div
      v-if="!service.user_skipped_modal"
      class="modal-overlay"
      @click="closeModal"
    >
      <div
        class="modal-content-container"
        @click.stop
      >
        <div class="modal-title">
          Vous vous apprêtez à être redirigé
        </div>
        <div
          class="modal-content"
          v-html="service.redirect_confirmation_information"
        />
        <div class="checkbox-skip">
          <input
            id="dont-show-again"
            v-model="dontShowAgain"
            type="checkbox"
          >
          <label
            for="dont-show-again"
            class="dont-show-again"
          >Ne plus afficher</label>
        </div>
        <div class="modal-actions mt-2">
          <button
            v-if="!loading && !errorUrl"
            class="button-home-redirection p-1"
            @click="confirmRedirect"
          >
            Je confirme
          </button>
          <div
            v-if="loading"
            class="button-home-redirection p-1"
          >
            Redirection en cours...
          </div>
          <div
            v-if="errorUrl"
            class="button-home-redirection p-1 custom-border"
          >
            Une erreur est survenue, merci de contacter le support.
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import { getUrlServiceTypeLink, updateSkipModalPreference } from '../../../services/api.js';

export default {
  props: {
    service: Object,
  },
  emits: ['close-link-modal', 'request-link-validated'],
  data() {
    return {
      redirectUrl: null,
      loading: false,
      errorUrl: false,
      dontShowAgain: false,
    };
  },
  async mounted() {
    if (this.service.user_skipped_modal) {
      await this.confirmRedirect();
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-link-modal');
    },
    async confirmRedirect() {
      this.loading = true;
      this.errorUrl = false;
      this.handleCheckboxChange();
      await this.getRedirectUrl();
      if (this.redirectUrl) {
        window.open(this.redirectUrl, '_blank');
        this.$emit('request-link-validated');
        this.closeModal();
      } else {
        this.errorUrl = true;
        const toast = useToast();
        toast.error('Une erreur est survenue, merci de contacter le support.');
      }
      this.loading = false;
    },
    async getRedirectUrl() {
      try {
        const response = await getUrlServiceTypeLink(this.service.id);
        this.redirectUrl = response.data.data.url;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'URL : ", error);
      }
    },
    handleCheckboxChange() {
      if (this.dontShowAgain) {
        this.updateSkipModalPreference();
      }
    },
    async updateSkipModalPreference() {
      try {
        await updateSkipModalPreference(this.service.id);
      } catch (error) {
        console.error(
          'Erreur lors de la mise à jour de la préférence : ',
          error,
        );
        const toast = useToast();
        toast.error(
          'Impossible de sauvegarder la préférence. Veuillez réessayer.',
        );
      }
    },
  },
};
</script>

<style scoped>
.checkbox-skip {
  display: flex;
  justify-content: start;
  align-items: center;
}

.dont-show-again{
  color: #052f5f ;
  font-weight: bold;
}

.modal-actions{
  display: flex;
  justify-content: flex-start;
}
</style>
