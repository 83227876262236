<template>
  <teleport to="body">
    <div
      class="modal-overlay"
      @click="cancelRequest"
    >
      <div class="modal-content-container">
        <div class="modal-title">
          {{ title }}
        </div>
        <div
          class="modal-content"
          v-html="content"
        />
        <div class="modal-actions">
          <button
            class="button-home-redirection p-1 mr-2"
            @click.stop="cancelRequest"
          >
            {{ textCancel }}
          </button>
          <button
            class="button-home-redirection p-1"
            :disabled="requestIsPending"
            @click.stop="confirmRequest"
          >
            <span v-if="requestIsPending">Demande de code en cours...</span>
            <span v-else>{{ textConfirm }}</span>
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'GenericModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    textCancel: {
      type: String,
      required: true,
    },
    textConfirm: {
      type: String,
      required: true,
    },
    requestIsPending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'cancel', 'closeRequestAccessModal', 'accessRequestValidated'],
  methods: {
    confirmRequest() {
      this.$emit('confirm');
    },
    cancelRequest() {
      this.$emit('cancel');
    },
  },
};
</script>
<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-container {
    background: white;
    border-radius: 5px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }

.modal-title {
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: #0f2e69;
}

.modal-content {
  margin-bottom: 20px;
  font-size: 16px;
  color: #052f5f;
  text-align: left;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.button-home-redirection {
     background-color: #fff;
     color: #052f5f;
     cursor: pointer;
     font-size: 18px;
     font-weight: bold;
     justify-content: flex-end;
   }

   .button-home-redirection:hover {
     background-color: #052f5f;
     color: #fff;
     cursor: pointer;
     font-size: 18px;
     font-weight: bold;
     justify-content: flex-end;
   }
</style>
