<template>
  <footer class="footer justify-content-center align-items-center">
    <BrandLogo
      class="logo-footer pl-9"
      :first-color="firstColor"
      :second-color="secondColor"
      :text-color="textColor"
    />
    <div
      class="footer-columns pl-5 pr-5 d-flex"
    >
      <ul class="d-flex flex-column flex-1">
        <li v-if="isAuthenticated">
          <router-link
            class="link-footer-color"
            to="/profil"
          >
            Mon Profil
          </router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link
            class="link-footer-color"
            to="/"
          >
            Catalogue
          </router-link>
        </li>
        <a
          class="link-footer-color"
          href="https://099ans.com/contact/"
          target="_blank"
        >Contact</a>
      </ul>
      <ul class="d-flex flex-column flex-2 ml-2">
        <li>
          <router-link
            class="link-footer-color"
            to="/conditions-generales-utilisation"
            target="_blank"
          >
            Conditions générales d'utilisation
          </router-link>
        </li>
        <li>
          <router-link
            class="link-footer-color"
            to="/politique-de-confidentialite"
            target="_blank"
          >
            Politique de confidentialité
          </router-link>
        </li>
        <li>
          <router-link
            class="link-footer-color"
            to="/politique-de-cookies"
            target="_blank"
          >
            Politique de cookies
          </router-link>
        </li>
      </ul>
    </div>
    <div class="footer-icons">
      <a
        href="https://www.facebook.com/099ans"
        target="_blank"
      >
        <i class="fab fa-facebook" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCl_4CH9aEiVmxsBNN0EiH-A"
        target="_blank"
      >
        <i class="ml-1 fab fa-youtube" />
      </a>
      <a
        href="https://www.linkedin.com/company/de0a99ans"
        target="_blank"
      >
        <i class="ml-1 fab fa-linkedin" />
      </a>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-left">
        Copyright © 099ans {{ new Date().getFullYear() }}. Tous droits réservés
      </div>
    </div>
  </footer>
</template>

<script>
import { useAuthStore } from '../store/store.js';
import BrandLogo from './atoms/BrandLogo.vue';

export default {
  components: { BrandLogo },
  data() {
    return {
      firstColor: '#fff',
      secondColor: '#052f5f',
      textColor: '#052f5f',
    };
  },
  computed: {
    isAuthenticated() {
      return useAuthStore().isAuthenticated;
    },
  },
};
</script>

<style scoped lang="scss">

.footer {
  height: 15.62rem;
  background-color: #052f5f;
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr;
  grid-gap: 1.25rem;
}

.footer-columns {
  line-height: 1.7rem;
  color: white;
}

.footer-icons {
  color: white;
  font-size: 1.6rem;
  margin: auto;
}

.footer-bottom {
  grid-column: 1 / -1;
  text-align: center;
  color: white;
}

.logo-footer {
  height: 6.25rem;
  width: 10.5rem;
}

.link-footer-color {
  color: #fff;
}

.link-footer-color:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    height: auto;
    padding: 1rem;
    text-align: center;
  }

  .logo-footer {
    margin: auto;
    padding-left: 0;
  }

}

i {
  color: #fff;
}

</style>
