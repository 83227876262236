<template>
  <div class="employee-number-form">
    <div
      v-if="user.verification_needed"
      class="alert alert-warning verification-alert"
      role="alert"
    >
      Compte en cours de vérification.
    </div>

    <div v-else>
      <small
        v-if="error"
        class="error text-danger error-message"
      >{{
        errorMessage
      }}</small>
      <p class="text">
        Pour valider votre compte merci de renseigner votre
        {{ company.employees_number_label }}
      </p>
      <form @submit.prevent="validateEmployeeNumber">
        <div class="form-group">
          <label
            for="employee-number-input"
            class="employee-form-label"
          >
            {{ company.employees_number_label }}</label>
          <input
            id="employee-number-input"
            v-model="employeeNumber"
            class="form-control input-large"
            type="text"
          >
        </div>
        <div class="btn">
          <button
            type="submit"
            class="button-home-redirection p-1"
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '../../store/store.js';
import { validateAndAssociateEmployeeNumber } from '../../services/api.js';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeNumber: '',
      error: false,
      errorMessage: '',
    };
  },
  methods: {
    async validateEmployeeNumber() {
      try {
        const response = await validateAndAssociateEmployeeNumber({
          employee_number: this.employeeNumber,
          company_id: this.company.id,
          user_id: this.user.id,
        });
        if (
          response
          && response.data
          && response.data.success
          && !response.data.data.verification_needed
        ) {
          this.$emit('validation-employee-number-success');
          this.error = false;
          this.errorMessage = '';
        } else if (
          response
          && response.data
          && response.data.data.verification_needed
        ) {
          const authStore = useAuthStore();
          authStore.setUserVerificationNeeded();
        } else {
          this.error = true;
          this.errorMessage = response.data.message || 'Erreur lors de la validation.';
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = error.response.data.message;
        console.warn(error);
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  margin: 0 0 1rem;
  color: #dc3545;
  text-align: center;
}
.verification-alert {
  font-size: 1rem;
  text-align: center;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  padding: 0.75rem 1.25rem;
  margin-top: -1px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 5rem 0;
}
.employee-number-form {
  max-width: 500px;
  margin: auto;
  padding: 2rem;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  margin-bottom:4.5rem;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.employee-form-label {
  display: block;
  align-self: center;
  padding-right: 7rem;
}
.employee-number-form .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.text {
  text-align: center;
}
</style>
