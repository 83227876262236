import { useAuthStore } from '../store/store.js';

export default function specificSpaceMiddleware(next) {
  const authStore = useAuthStore();
  if (!authStore.getUser || !authStore.getUser.company || !authStore.getUser.company.name) {
    next('/login');
  } else {
    const companySubDomain = authStore.getUser.company.subdomain;
    const { hostname } = window.location;
    const domainName = hostname.substring(hostname.indexOf('.') + 1);
    const currentSubDomain = window.location.hostname.split('.')[0];
    if (currentSubDomain === process.env.VUE_APP_MAIN_SUB_DOMAIN || currentSubDomain !== companySubDomain) {
      window.location.href = `${process.env.VUE_APP_HOST_HTTP}://${companySubDomain}.${domainName}:${process.env.VUE_APP_HOST_PORT}/`;
    }
  }
}
