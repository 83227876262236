<template>
  <div
    v-if="isAdminConnectAs || isAdminConnectAsLS"
    class="notification-banner"
  >
    <div class="scrolling-text">
      Vous êtes connecté en tant qu'utilisateur via un compte administrateur. /!\ PENSEZ A VOUS DECONNECTER
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '../../store/store.js';

const authStore = useAuthStore();
const isAdminConnectAs = ref(authStore.adminConnectAs);
const isAdminConnectAsLS = ref();

onMounted(() => {
  isAdminConnectAs.value = authStore.adminConnectAs;
  isAdminConnectAsLS.value = localStorage.getItem('adminConnectAs');
});
</script>

<style scoped>
.notification-banner {
  background-color: #ffcc00;
  width: 100%;
  color: #000;
  padding: 10px;
  height: 25px;
  position: fixed;
  text-align: center;
  font-weight: bold;
  z-index: 10001;
}

.scrolling-text {
  white-space: nowrap;
  position: absolute;
  animation: scroll 15s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
