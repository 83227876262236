<template>
  <button
    class="button-home-redirection p-1"
    @click="openConfirmationModalLink"
  >
    Vers le site
  </button>
</template>
<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-modal-link', 'open-confirmation-modal-link'],
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('close-modal-link');
    },
    openConfirmationModalLink() {
      this.$emit('open-confirmation-modal-link', this.service);
    },
  },
};
</script>
