<template>
  <teleport to="body">
    <div
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal-content-container">
        <div class="modal-title">
          Vérification du numéro de téléphone
        </div>
        <div class="modal-content">
          <p>Un SMS de vérification a été envoyé sur votre numéro.</p>
          <input
            v-model="userInputCode"
            type="text"
            placeholder="Entrez le code SMS"
          >
          <div
            v-if="errorCode"
            class="error-message"
          >
            {{ errorCode }}
          </div>
        </div>
        <div class="modal-actions">
          <button
            class="button-home-redirection p-1 mr-1"
            @click="closeModal"
          >
            Annuler
          </button>
          <button
            class="button-home-redirection p-1"
            @click="verifyCode"
          >
            Vérifier
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { verifySmsCodeForPhoneVerification } from '../../services/api.js';

export default {
  data() {
    return {
      userInputCode: '',
      errorCode: '',
    };
  },
  methods: {
    async verifyCode() {
      const payload = { code: this.userInputCode };
      try {
        const response = await verifySmsCodeForPhoneVerification(payload);
        if (response.data.success) {
          this.$emit('verification-phone-success', response.data.data.phone_verified_at);
          this.closeModal();
        } else {
          this.errorCode = 'Code non valide.';
        }
      } catch (error) {
        this.errorCode = 'Erreur lors de la vérification.';
      }
    },
    closeModal() {
      this.$emit('close-verification-phone-modal');
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
