<template>
  <DecorationComponent />
  <div
    class="container mb-2 mt-2"
  >
    <div class="logo-wrapper">
      <img
        class="logo"
        :src="logo"
        alt="logo"
      >
    </div>
    <slot />
  </div>
</template>

<script>

import { useAuthStore } from '../store/store.js';
import DecorationComponent from './DecorationComponent.vue';

export default {
  components: { DecorationComponent },
  props: {
    username: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      logo: null,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    const authStore = useAuthStore();
    if (authStore.getUser && authStore.getUser.company && authStore.getUser.company.name) {
      this.logo = process.env.VUE_APP_BASE_MEDIA_URL + authStore.getUser.company.logo;
    }
  },
};
</script>

<style scoped lang="scss">

.container {
  display: flex;
  flex-direction: row;
}

.logo-wrapper {
  flex: 1;
  max-width: 50%;
}

.logo {
  width: 400px;
  max-width: 100%;
  margin-left: 11rem;
  }

.confirmation-message {
  background-color: lightgreen;
  color: darkgreen;
  width: 40%;
  height: 1.5rem;
  text-align: center;
  font-weight: bolder;
  padding-top: 0.3rem;
}

.text-danger {
  color: red;
  font-size: 0.8rem;
}

@media screen and (max-width: 1310px) {
  .logo-wrapper{
    margin: 0 auto;
  }
  .logo {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 1060px) {
  .container {
    flex-direction: column;
  }

  .text-wrapper{
    margin-right: 0;
    text-align: center;
  }

  .logo-wrapper {
    margin: 0 auto;
  }
  .logo{
    margin-left: 0rem;
  }

  .welcome-text{
    margin-right: 0;
  }

}

</style>
