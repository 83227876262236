<template>
  <div>
    <template v-if="!service.can_make_form_request_again">
      <div
        class="button-home-redirection custom-border p-1"
        disabled
      >
        Vous ne pouvez pas encore renvoyer ce formulaire
      </div>
    </template>
    <template v-else>
      <button
        class="button-home-redirection p-1"
        @click="requestHelp"
      >
        Demander une aide
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  methods: {
    requestHelp() {
      this.$emit('open-confirmation-modal-form', this.service);
    },
  },
};
</script>
