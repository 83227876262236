<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="149.217px"
    height="37.609px"
    viewBox="88.217 0 149.217 37.609"
  >
    <path
      :fill="firstColor"
      d="M125.559,0h46.657v37.609h-46.657V0z"
    />

    <path
      :fill="textColor"
      d="M147.758,16.037v8.872c0.018,1.018-0.29,2.014-0.878,2.844c-0.629,0.858-1.484,1.523-2.471,1.921
c-1.166,0.477-2.418,0.709-3.677,0.685c-1.23,0.022-2.452-0.21-3.587-0.685c-0.97-0.398-1.81-1.059-2.428-1.905
c-0.58-0.827-0.883-1.818-0.863-2.828v-0.983c-0.001-0.196,0.156-0.355,0.353-0.356c0.002,0,0.003,0,0.004,0h4.229
c0.196-0.002,0.355,0.156,0.356,0.353c0,0.001,0,0.003,0,0.004v0.269c-0.013,0.504,0.188,0.99,0.553,1.339
c0.379,0.362,0.889,0.556,1.412,0.537c0.543,0.02,1.072-0.173,1.475-0.537c0.384-0.35,0.597-0.85,0.582-1.369v-2.291
c0.006-0.049-0.018-0.096-0.061-0.121c-0.041-0.019-0.091-0.006-0.118,0.03c-0.872,0.605-1.917,0.909-2.978,0.863
c-1.172,0.012-2.325-0.298-3.335-0.894c-1.02-0.603-1.838-1.493-2.352-2.56c-0.448-1.001-0.662-2.09-0.625-3.186
c-0.02-1.03,0.173-2.054,0.566-3.007c0.525-1.201,1.416-2.207,2.545-2.874c2.542-1.414,5.636-1.408,8.172,0.015
c1.146,0.685,2.044,1.718,2.563,2.947C147.581,14.043,147.772,15.036,147.758,16.037z M142.786,15.948
c0.014-0.562-0.119-1.116-0.387-1.61c-0.358-0.661-1.068-1.054-1.819-1.006c-0.751-0.046-1.465,0.334-1.846,0.983
c-0.313,0.486-0.47,1.059-0.448,1.637c-0.021,0.599,0.135,1.19,0.448,1.7c0.399,0.587,1.075,0.926,1.785,0.894
c0.738,0.059,1.449-0.298,1.846-0.924C142.664,17.116,142.809,16.535,142.786,15.948z"
    />
    <path
      :fill="textColor"
      d="M163.658,16.037v8.872c0.018,1.018-0.291,2.014-0.879,2.844c-0.629,0.858-1.484,1.523-2.471,1.921
c-1.167,0.477-2.418,0.709-3.678,0.685c-1.229,0.022-2.451-0.21-3.587-0.685c-0.969-0.398-1.81-1.059-2.427-1.905
c-0.581-0.827-0.884-1.818-0.864-2.828v-0.983c0-0.196,0.158-0.356,0.355-0.356c0,0,0.001,0,0.002,0h4.227
c0.196-0.002,0.356,0.156,0.357,0.353c0,0.001,0,0.003,0,0.004v0.269c-0.014,0.504,0.188,0.99,0.552,1.339
c0.379,0.362,0.889,0.556,1.413,0.537c0.543,0.02,1.071-0.173,1.474-0.537c0.385-0.35,0.598-0.85,0.582-1.369v-2.291
c0.006-0.049-0.018-0.096-0.06-0.121c-0.042-0.019-0.091-0.006-0.119,0.03c-0.871,0.605-1.917,0.909-2.977,0.863
c-1.173,0.012-2.326-0.298-3.335-0.894c-1.021-0.603-1.839-1.493-2.353-2.56c-0.447-1.001-0.661-2.09-0.625-3.186
c-0.02-1.03,0.174-2.054,0.567-3.007c0.525-1.202,1.416-2.207,2.545-2.874c2.542-1.414,5.635-1.408,8.172,0.015
c1.146,0.685,2.043,1.718,2.562,2.947C163.479,14.043,163.672,15.036,163.658,16.037z M158.685,15.948
c0.014-0.562-0.12-1.116-0.388-1.61c-0.355-0.662-1.064-1.057-1.815-1.011c-0.751-0.046-1.465,0.334-1.846,0.983
c-0.313,0.486-0.47,1.058-0.448,1.637c-0.021,0.599,0.134,1.189,0.448,1.7c0.399,0.587,1.075,0.926,1.784,0.894
c0.739,0.058,1.45-0.298,1.847-0.924C158.563,17.112,158.709,16.533,158.685,15.948z"
    />
    <rect
      x="172"
      y="0"
      fill="#052F5F"
      width="65.778"
      height="37.609"
    />
    <path
      fill="#FFFFFF"
      d="M190.039,29.824l-0.773-2.651c-0.04-0.078-0.09-0.118-0.15-0.118h-6.788c-0.059,0-0.109,0.039-0.148,0.118
l-0.773,2.651c-0.045,0.189-0.223,0.317-0.417,0.3h-4.555c-0.101,0.01-0.199-0.027-0.269-0.1c-0.063-0.092-0.075-0.209-0.031-0.312
l6.373-20.128c0.036-0.182,0.2-0.31,0.386-0.3h5.628c0.186-0.01,0.35,0.118,0.387,0.3l6.4,20.128
c0.021,0.046,0.032,0.097,0.03,0.147c0,0.18-0.109,0.269-0.327,0.269h-4.555C190.26,30.146,190.082,30.016,190.039,29.824z
 M183.489,23.244h4.436c0.119,0,0.159-0.061,0.119-0.18l-2.263-7.651c-0.019-0.079-0.049-0.114-0.089-0.1
c-0.04,0.014-0.07,0.043-0.088,0.1l-2.234,7.651c-0.02,0.119,0.023,0.179,0.123,0.179L183.489,23.244z"
    />
    <path
      fill="#FFFFFF"
      d="M209.438,9.382c0.067-0.065,0.158-0.102,0.253-0.1h4.228c0.095-0.002,0.186,0.034,0.253,0.1
c0.067,0.067,0.104,0.159,0.101,0.253v20.129c0,0.196-0.16,0.356-0.357,0.356h-4.049c-0.175,0.015-0.341-0.08-0.418-0.238
L202.6,18.509c-0.04-0.061-0.079-0.085-0.119-0.074c-0.04,0.01-0.06,0.056-0.06,0.134l0.029,11.2
c0.001,0.196-0.158,0.356-0.354,0.356c-0.001,0-0.002,0-0.003,0h-4.228c-0.196,0.001-0.355-0.156-0.357-0.353
c0-0.002,0-0.003,0-0.004V9.635c-0.002-0.094,0.034-0.186,0.101-0.253c0.067-0.065,0.159-0.102,0.254-0.1h4.078
c0.175-0.014,0.34,0.08,0.417,0.238l6.817,11.345c0.04,0.059,0.079,0.085,0.119,0.074s0.062-0.053,0.062-0.134L209.325,9.64
C209.324,9.542,209.365,9.448,209.438,9.382z"
    />
    <path
      fill="#FFFFFF"
      d="M220.482,29.57c-1.143-0.464-2.125-1.25-2.828-2.263c-0.674-1.008-1.023-2.198-1-3.41v-0.565
c-0.003-0.095,0.033-0.186,0.1-0.253c0.067-0.067,0.159-0.104,0.253-0.1h4.14c0.095-0.003,0.186,0.033,0.253,0.1
c0.065,0.067,0.102,0.159,0.1,0.253v0.357c0.022,0.698,0.392,1.34,0.983,1.711c0.78,0.524,1.71,0.78,2.648,0.729
c0.693,0.058,1.383-0.145,1.937-0.565c0.391-0.323,0.619-0.802,0.625-1.31c0.014-0.36-0.121-0.711-0.373-0.969
c-0.289-0.272-0.629-0.484-1-0.625c-0.417-0.168-1.063-0.401-1.937-0.7c-0.113-0.057-0.233-0.102-0.356-0.134
c-0.111-0.025-0.221-0.06-0.327-0.1c-1.155-0.363-2.28-0.816-3.365-1.355c-0.953-0.486-1.786-1.178-2.44-2.024
c-0.694-0.92-1.052-2.049-1.014-3.2c-0.023-1.152,0.315-2.282,0.968-3.231c0.679-0.949,1.613-1.688,2.693-2.129
c2.584-1.022,5.466-0.99,8.025,0.091c1.157,0.503,2.151,1.317,2.872,2.353c0.699,1.024,1.064,2.241,1.043,3.481v0.417
c0.003,0.095-0.033,0.188-0.101,0.254c-0.066,0.067-0.158,0.104-0.253,0.101h-4.139c-0.095,0.003-0.187-0.033-0.254-0.101
c-0.067-0.066-0.104-0.159-0.1-0.254v-0.208c-0.014-0.723-0.353-1.4-0.923-1.846c-0.695-0.564-1.576-0.851-2.471-0.805
c-0.641-0.037-1.276,0.129-1.817,0.476c-0.431,0.307-0.677,0.811-0.653,1.339c-0.014,0.404,0.136,0.797,0.416,1.089
c0.377,0.35,0.818,0.621,1.3,0.801c0.583,0.249,1.481,0.571,2.694,0.967c1.389,0.479,2.47,0.896,3.244,1.251
c0.848,0.415,1.595,1.009,2.188,1.742c0.717,0.896,1.083,2.024,1.028,3.172c0.031,1.188-0.313,2.354-0.983,3.335
c-0.695,0.966-1.65,1.714-2.754,2.158c-1.309,0.53-2.712,0.788-4.124,0.759C223.309,30.39,221.846,30.122,220.482,29.57z"
    />
    <rect
      x="88.611"
      y="0"
      :fill="secondColor"
      width="37.07"
      height="37.609"
    />
    <path
      fill="#FFFFFF"
      d="M102.756,28.558c-1.354-1.393-2.065-3.287-1.965-5.227v-7.264c-0.104-1.918,0.61-3.79,1.965-5.152
c3.169-2.507,7.646-2.5,10.809,0.016c1.368,1.348,2.096,3.217,2,5.136v7.264c0.1,1.946-0.627,3.844-2,5.227
c-1.479,1.335-3.429,2.026-5.418,1.919C106.165,30.588,104.221,29.896,102.756,28.558z M109.946,25.52
c0.469-0.525,0.71-1.216,0.671-1.919v-7.713c0.039-0.705-0.202-1.396-0.671-1.923c-0.475-0.477-1.128-0.731-1.8-0.699
c-0.659-0.03-1.299,0.226-1.757,0.699c-0.461,0.531-0.695,1.221-0.655,1.923v7.711c-0.04,0.697,0.188,1.385,0.64,1.919
c0.461,0.48,1.107,0.736,1.772,0.699c0.671,0.032,1.324-0.222,1.798-0.698L109.946,25.52z"
    />
  </svg>
</template>
<script>
export default {
  name: 'BrandLogo',
  props: {
    firstColor: {
      type: String,
      default: '#ed1a3a',
    },
    secondColor: {
      type: String,
      default: '#f9991c',
    },
    textColor: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
