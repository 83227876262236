<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center services-filter mr-2 mt-1"
    :class="{ 'selected-services-filter': isSelected }"
    @click.prevent="$emit('select', service.id)"
  >
    <img
      :src="`${apiBaseMedia}${service.pictogram}`"
      alt="Pictogram du service"
      class="h-3 service-image"
    >
    <div
      class="text-center service-label mt-0-5"
      v-html="service.label_icon"
    />
  </div>
</template>

<script>
export default {
  props: {
    service: Object,
    isSelected: Boolean,
  },
  data() {
    return {
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
    };
  },
};
</script>

<style scoped>
.service-image {
  display: block;
  margin: auto;
}

.service-label {
  width: 100%;
  color: #052f5f;
}
.services-filter{
  opacity: 0.3;
  cursor: pointer;
}

.services-filter:hover , .selected-services-filter{
  opacity: 1;
}

</style>
