<template>
  <teleport to="body">
    <div class="modal-overlay">
      <div class="modal-content-container">
        <button
          class="close-button"
          @click="closeModal"
        >
          X
        </button>
        <div class="modal-title">
          <template v-if="displayLogginForm">
            <template v-if="!success">
              Connexion
            </template>
            <template v-else>
              Vous êtes connecté !
            </template>
          </template>
          <template v-else>
            Première connexion ?
          </template>
        </div>
        <div class="modal-content">
          <div
            v-if="error"
            class="error-message ml-2"
          >
            <div class="font-medium text-red-600">
              Oups ! Un problème est survenu.
            </div>
            <ul class="mt-3 text-sm text-red-600 list-style">
              <li>{{ errorMsg }}</li>
            </ul>
          </div>

          <template v-if="displayLogginForm && !success">
            <form
              class="form flex-1 mt-6 access-request"
              @submit.prevent="loginPartnerWebsite"
            >
              <div class="form-group ml-2 mt-2 mb-1">
                <label
                  for="email"
                  class="input-wrapper__label"
                >E-mail</label>
                <input
                  id="email"
                  v-model="login"
                  class="input-wrapper__input p-0-5"
                  type="email"
                >
              </div>

              <div class="form-group ml-2 mt-2 mb-1">
                <label
                  for="password"
                  class="input-wrapper__label"
                >Mot de passe</label>
                <input
                  id="password"
                  v-model="password"
                  class="input-wrapper__input p-0-5"
                  type="password"
                >
              </div>

              <a
                href="https://app.lesiteduparent.com/mot-de-passe/reinitialisation"
                target="_blank"
                class="forgot-password mt-2 ml-2"
              >Mot de passe oublié ?</a>
            </form>
          </template>
          <template v-else-if="!displayLogginForm">
            <div class="first-connection">
              <p v-if="!autoCreatedAccount">
                Avez-vous déjà un compte Prof Express ?
              </p>
            </div>
          </template>
          <div class="modal-actions">
            <template v-if="displayLogginForm && !success">
              <button
                class="button-home-redirection p-1"
                :disabled="loading"
                @click="loginPartnerWebsite"
              >
                {{ loading ? 'Connexion en cours...' : 'Connexion' }}
              </button>
            </template>
            <template v-if="success">
              <a
                :title="service.partner.url"
                target="_blank"
                :href="redirectUrl"
                @click="closeModal"
              >
                <button class="button p-1">
                  {{ redirectUrl ? 'Cliquer ici pour être redirigé' : 'Chargement en cours...' }}
                </button>
              </a>
            </template>
            <template v-if="!displayLogginForm && !success">
              <button
                class="button-home-redirection p-1"
                @click="setDisplayLogginForm"
              >
                Oui
              </button>
              <button
                class="button-home-redirection p-1"
                @click="noAccount"
              >
                Non
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useAuthStore } from '../../../store/store.js';
import { getUrlServiceTypeLink, loginProfExpress } from '../../../services/api.js';

export default {
  name: 'ProfExpressLoginModal',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      login: '',
      password: '',
      error: false,
      errorMsg: '',
      success: false,
      loading: false,
      redirectUrl: '',
      displayLogginForm: false,
      autoCreatedAccount: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    async noAccount() {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        this.success = true;
        const urlResponse = await getUrlServiceTypeLink(this.service.id);
        this.redirectUrl = urlResponse.data.data.url;
        authStore.setHasProfExpressAccount();
        this.autoCreatedAccount = true;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
        this.redirectUrl = '';
      }
    },
    setDisplayLogginForm() {
      this.displayLogginForm = true;
    },
    async loginPartnerWebsite() {
      if (this.password === '' || this.login === '') {
        this.errorMsg = 'Veuillez renseigner vos identifiants';
        this.error = true;
        return;
      }

      this.loading = true;

      const authStore = useAuthStore();

      try {
        const response = await loginProfExpress({
          login: this.login,
          password: this.password,
          service_id: this.service.id,
        });
        const userId = response.user_id ?? null;
        if (userId) {
          this.error = false;
          this.errorMsg = '';
          this.login = '';
          this.password = '';
          this.success = true;
          const urlResponse = await getUrlServiceTypeLink(this.service.id);
          this.redirectUrl = urlResponse.data.data.url;
          authStore.setHasProfExpressAccount();
          this.loading = false;
        } else {
          this.errorMsg = 'Identifiants incorrects';
          this.error = true;
          this.loading = false;
        }
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
        this.errorMsg = 'Identifiants incorrects';
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-actions {
  display: flex;
  justify-content: space-around;
  margin: 2rem 2rem 0 2rem;
}

.error-message {
  color: red;
}

.list-style {
  list-style: inside;
}

.form-group {
  max-width: 355px;
  margin: 2rem auto;
  label {
    display: block;
    font-weight: bold;
    color: #052f5f;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
    max-width: 355px;
    border: none;
    border-bottom: 1px solid #052f5f;
    height:32px;
    font-size: 1em;
  }
}

.forgot-password {
  display: block;
  font-size: 0.8em;
  color: red;
  max-width: 355px;
  margin: auto;
}

.first-connection p {
  margin: 0;
  font-size: 1.2em;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
