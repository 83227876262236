import {
  createApp,
} from 'vue';
import {
  createPinia,
} from 'pinia';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import './assets/styles.scss';
import router from './router/router.js';
import '@fortawesome/fontawesome-free/css/all.css';

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [process.env.VUE_APP_API_BASE_URL, /^api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
app.use(router).use(pinia).mount('#app');
