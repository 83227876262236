<template>
  <section class="container mx-auto">
    <h1>
      <span class="discover-title">POLITIQUE DE CONFIDENTIALITÉ</span>
    </h1>

    <p><span>Mise à jour le 27/02/2022</span></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>CLASSIP,
        dont le siège social est situé au 440 avenue Marcel Paul - Bâtiment 3 L’Alizé -
        La Rose des Vents - 13400 AUBAGNE, en sa qualité de responsable du traitement,
        attache une grande importance à la protection des données personnelles et au
        respect de la vie privée des utilisateurs (ci-après “Utilisateur”).</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>La présente
        politique de confidentialité a pour but de vous informer, conformément au
        Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes
        physiques à l’égard du traitement des données à caractère personnel et à la
        libre circulation de ces données (ci-après dénommé le « Règlement »), de nos
        pratiques concernant la collecte, l’utilisation et le partage des données
        personnelles que vous êtes amenés à nous fournir par le biais de notre site
        internet </span>
      <a href="https://099ans.com">
        https://099ans.com
      </a>
      <span> (ci-après
        le “Site”), ainsi que les droits dont vous disposez sur vos données
        personnelles.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>1. LA COLLECTE DES DONNÉES PERSONNELLES</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>En
        utilisant le Site, vous êtes amenés à nous transmettre des données, dont
        certaines sont de nature à vous identifier et constituent de ce fait des
        données à caractère personnel (ci-après dénommées les <b>« Données »</b>).
        C’est notamment le cas lorsque vous créez un compte utilisateur sur la
        plateforme ou si vous complétez l’un de nos formulaires.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <span>Les Données collectées dans ce cadre sont les suivantes : </span>
    </p>

    <p><span>&nbsp;</span></p>

    <ul>
      <li>
        <p>
          • <b>Données d’identification </b>
          <span>: désignent les Données que vous renseignez lors de la création du
            compte utilisateur ou lors de la demande d’un essai gratuit de la plateforme.
            Elles comprennent le nom, le prénom, l’adresse e-mail et le numéro de
            téléphone.</span>
        </p>
      </li>
      <li>
        <p>
          • <b>Données relatives à la navigation sur le Site</b>
          <span>: désignent les Données que nous
            collectons lors de votre navigation sur le Site, telles que notamment la date,
            l’heure de la connexion et/ou navigation, le type de navigateur, la langue du
            navigateur, son adresse IP.</span>
        </p>
      </li>
    </ul>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Lors de la
        collecte de vos Données, nous vous informons si certaines Données doivent être
        obligatoirement renseignées ou si elles sont facultatives. Les Données
        identifiées par un astérisque au sein des formulaires sont obligatoires. Si les
        Données obligatoires ne sont pas renseignées, vous ne pourrez pas utiliser les
        services du Site, notamment celui de solliciter des renseignements ou créer un
        compte utilisateur.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>2. L’UTILISATION DES DONNÉES PERSONNELLES COLLECTÉES</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p><span>Nous utilisons les Données que nous recueillons afin de : </span></p>

    <p><span>&nbsp;</span></p>

    <ul>
      <li>
        <p>
          <span>• Permettre aux Utilisateurs de créer un compte sur la plateforme et de bénéficier des
            services ;</span>
        </p>
      </li>
      <li>
        <p>
          <span>• Permettre aux Clients de tester gratuitement la plateforme;</span>
        </p>
      </li>
      <li>
        <p>
          <span>• Mesurer le nombre de visiteurs sur le Site.</span>
        </p>
      </li>
    </ul>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Conformément
        à la législation applicable et avec votre consentement lorsqu’il est requis,
        nous pourrons également utiliser les données que vous nous fournissez sur notre
        Site à des fins de prospection commerciale (par exemple pour vous adresser nos
        newsletters ou toute autre communication susceptible de vous intéresser).</span>
    </p>

    <p>
      <span>Vous pouvez
        à tout moment retirer votre consentement en cliquant sur le lien de
        désabonnement fourni dans chacune de nos communications ou en nous contactant
        selon les modalités décrites à l’article 7 ci-dessous.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>3. DESTINATAIRES DES DONNÉES PERSONNELLES</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Nous
        travaillons en collaboration avec des entreprises tierces qui peuvent avoir
        accès à vos Données.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Afin
        d’assurer la meilleure qualité de service possible, nous sommes conduits à
        communiquer les données collectées à différents destinataires, dûment habilités
        à cet effet, à savoir :</span>
    </p>

    <p><span>&nbsp;</span></p>

    <ul>
      <li>
        <p>
          <span>• Nos services internes (administratif, commercial, technique)</span>
        </p>
      </li>
      <li>
        <p>
          <span>• Nos sous-traitants opérateurs techniques</span>
        </p>
      </li>
      <li>
        <p>
          <span>• Nos partenaires commerciaux</span>
        </p>
      </li>
    </ul>

    <p><span>&nbsp;</span></p>

    <p>
      <span>S’agissant
        de ces derniers, dans le cas où vous accéder à des services par l’intermédiaire
        du Site, le responsable du traitement des données collectées et/ou traitées
        dans le cadre de l’accès à ce service est le partenaire proposant le service.
        Nous attirons votre attention sur le fait que, dans ce cas-là, leurs politiques
        de confidentialité vous est opposable. Nous n’avons aucun contrôle sur la
        collecte ou le traitement de vos données mis en œuvre par nos partenaires
        commerciaux sur leur propre plateforme.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>4. TRANSFERTS DE DONNÉES HORS DE L’UE</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Les
        Données que nous traitons sont hébergées au sein de l’Union européenne dans le
        respect des exigences de la Réglementation applicable.</span>
    </p>

    <p>
      <span>Nous
        garantissons, en cas de transfert de vos données hors de l’Union européenne,
        mettre en oeuvre toutes les mesures appropriées pour garantir un niveau de
        protection suffisant de vos données. Il s’agit notamment d’orienter nos choix
        de prestataires vers des pays reconnus par les autorités de contrôle
        européennes comme délivrant un niveau de protection « adéquat » ; ou d’obtenir
        les garanties (certification ou clauses contractuelles spécifiques) permettant
        de les contrôler, notamment en conduisant auprès d’eux des audits de sécurité
        des données à caractère personnel.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>5. DURÉE DE CONSERVATION DES DONNÉES</span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Vos Données
        ne seront pas conservées par CLASSIP au-delà de la durée strictement nécessaire
        aux finalités poursuivies telles qu’énoncées dans la présente politique et ce
        conformément au Règlement et aux lois applicables. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Lorsque les
        durées de conservation arrivent à leur terme, vos Données sont effacées.
        Néanmoins, vos Données pourront éventuellement être archivées au-delà des
        durées prévues pour les besoins de la recherche, la constatation et de la
        poursuite des infractions pénales dans le seul but de permettre, en tant que de
        besoin, la mise à disposition de vos Données à l’autorité judiciaire.
        L’archivage implique que vos Données ne seront plus consultables en ligne mais
        seront extraites et conservées sur un support autonome et sécurisé.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>6. SÉCURITÉ DES DONNÉES</span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>CLASSIP
        prend les mesures nécessaires adéquates sur le plan de la technique et
        organisationnel pour interdire l’accès non autorisé ou la modification,
        divulgation, perte ou destruction de vos Données.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>À
        cette fin, nous mettons en place des mesures techniques telles que des
        parefeux; des mesures organisationnelles telles qu’un système d’identifiant et
        mot de passe unique pour chacun de nos collaborateurs, internes ou externes ;
        des moyens de protection physiques, etc.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>7. VOS DROITS SUR LES DONNÉES</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Conformément
        aux lois et règlements applicables en matière de protection des données
        personnelles, vous bénéficiez d’un certain nombre de droits relatifs à vos
        Données, à savoir : </span>
    </p>

    <p><span>&nbsp;</span></p>

    <ul>
      <li>
        <p>
          <span>• <b>Un droit d’accès et d’information : </b>CLASSIP vous informe de manière concise,
            transparente, intelligible et facilement accessible de la manière dont vos
            Données sont traitées. Vous avez également le droit, sur demande, d’accéder à
            ces Données et d’en obtenir une copie. </span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Un droit de rectification : </b>vous avez le droit d’obtenir la rectification des
            Données inexactes vous concernant. Vous avez également le droit de compléter
            les Données incomplètes vous concernant, en nous fournissant une déclaration
            complémentaire. En cas d’exercice de ce droit, nous nous engageons à
            communiquer toute rectification à l’ensemble des destinataires de vos Données. </span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Un droit d’effacement : </b>dans certains cas, vous avez le droit d’obtenir
            l’effacement de vos Données. Cependant, nous pouvons, pour des raisons légales
            ou légitimes, conserver ces Données. </span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Un droit à la limitation du traitement : </b>dans certains cas, vous avez le droit
            d’obtenir la limitation du traitement sur vos Données. </span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Un droit à la portabilité des Données : </b>vous avez le droit de recevoir vos
            Données que vous nous avez fournies, dans un format structuré, couramment
            utilisé et lisible par une machine, pour votre usage personnel ou pour les
            transmettre à un tiers de votre choix. Ce droit ne s’applique que lorsque le
            traitement de vos Données est basé sur votre consentement, sur un contrat et
            que ce traitement est effectué par des moyens automatisés. </span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Un droit d’opposition au traitement : </b>vous avez le droit de vous opposer à
            tout moment au traitement de vos Données pour les traitements basés sur notre
            intérêt légitime, une mission d’intérêt public et ceux à des fins de
            prospection commerciale. Cependant, nous pouvons, pour des raisons légales ou
            légitimes, refuser votre demande d’opposition.</span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Le droit de retirer votre consentement à tout moment : </b>lorsque le traitement
            est basé sur votre consentement, vous pouvez le retirer à tout moment.</span>
        </p>
      </li>
      <li>
        <p>
          <span>• <b>Le droit de déposer une plainte auprès d’une autorité de contrôle : </b>si vous
            estimez que vos droits en matière de protection des données et de la vie privée
            n’ont pas été respectés, vous avez le droit de contacter l’autorité de
            protection des données (CNIL) pour déposer une plainte. </span>
        </p>
      </li>
    </ul>

    <p>
      <span>Pour
        exercer ces droits, vous pouvez contacter CLASSIP par e-mail à contact@de0a99ans.com
        ou par voie postale</span>
      <span>à l’adresse suivante :</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>CLASSIP</span></p>
    <p><span>Immeuble EUROPA, Bâtiment A</span></p>
    <p><span>310 avenue de l’Europe</span></p>
    <p><span>44240 SUCÉ SUR ERDRE</span></p>
    <p><span>&nbsp;</span></p>

    <p>
      <span>Il est
        possible qu’un justificatif de votre identité vous soit demandé pour l’exercice
        de ces droits.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>8.MODIFICATION DE LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ</span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        pouvons être amenés à modifier occasionnellement la présente politique, afin
        notamment de se conformer à toutes évolutions réglementaires,
        jurisprudentielles, éditoriales ou techniques. Le cas échéant, nous changerons
        la date de de mise à jour et indiquerons la date à laquelle les modifications
        ont été apportées. Lorsque cela est nécessaire, notamment mais pas
        exclusivement en cas de modification substantielle ou d’évènement particulier
        requérant la modification de la présente politique, nous vous informerons et/ou
        solliciterons votre accord. Nous vous conseillons de consulter régulièrement
        cette page pour prendre connaissance des éventuelles modifications ou mises à
        jour apportées à notre politique. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>9. CONTACT </span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Pour toute question relative à la présente politique ou pour toute
        demande relative à vos Données, vous pouvez nous contacter en adressant un
        e-mail à l’adresse électronique suivante : contact@de0a99ans.com.</span>
    </p>

    <p>&nbsp;</p>

    <p>&nbsp;</p>
  </section>
  <section class="container mx-auto">
    <h1>
      <span class="discover-title">POLITIQUE DE COOKIES</span>
    </h1>

    <p><span>Mise à jour le : 27/02/2022</span></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>CLASSIP (ci-après «CLASSIP» ou «nous») attache une grande
        importance à la protection des données personnelles (ci-après les
        «Données») des utilisateurs (ci-après les
        «Utilisateurs») de son site internet </span>
      <span>
        <a href="https://099ans.com">
          <span>https://099ans.com</span>
        </a>
      </span>
      <span> (ci-après
        le «Site»). La présente politique (ci-après la
        «Politique») a pour but de vous informer sur la manière dont nous
        collectons, en tant que responsable de traitement, vos Données par le biais de
        cookies présents sur notre Site et comment les paramétrer.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>1. QU’EST-CE QU’UN COOKIE ?</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Un cookie
        est un petit fichier texte enregistré sur le terminal de votre navigateur
        (ordinateur, smartphone, tablette, etc.) lors de la visite du Site. Nous
        utilisons des cookies pour collecter des informations relatives à votre
        navigation, vos préférences et vos configurations, afin de vous adresser un
        contenu et des services personnalisés et de nous permettre d’analyser vos
        actions sur notre Site.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        utilisons un gestionnaire de cookies qui nous permet de mettre à votre
        disposition un moyen de récolter votre consentement sur notre Site. Ce
        gestionnaire permet aux Utilisateurs de visualiser l’ensemble des cookies que
        nous utilisons, et de consentir ou de refuser, à tout moment, tout ou partie
        des cookies utilisés lorsque ceux-ci ne sont pas nécessaires au fonctionnement
        du Site.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>En effet,
        certains cookies sont strictement nécessaires pour faire fonctionner notre
        Site. Du fait de leur caractère indispensable, ceux-ci sont exemptés du recueil
        de votre consentement. L’Utilisateur dispose de la possibilité d’accepter ou de
        refuser les autres cookies en utilisant le mécanisme intégré au gestionnaire de
        cookies, disponible à tout moment sur le bas de l’écran.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>2. QUELS TYPES DE COOKIES SONT COLLECTÉS ? </span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Nous
        utilisons des cookies pour collecter des informations sur les Utilisateurs de
        notre Site, afin de les mémoriser soit pendant la durée de votre visite
        (cookies dits “temporaires”) soit pour des visites répétées (cookies dits
        “persistants”).</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        utilisons plusieurs types de cookies sur notre Site, dont les caractéristiques
        sont décrites ci-dessous.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>2.1. Les cookies techniques strictement nécessaires</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Ces cookies
        sont nécessaires au fonctionnement du Site. Ils permettent à l’Utilisateur d’en
        utiliser les principales fonctionnalités. Sans ces cookies, l’Utilisateur ne
        peut pas utiliser le Site normalement. Ils requièrent uniquement une
        information de l’Utilisateur pour être déposés sur son terminal et ne
        permettent aucun suivi comportemental ou d’identification. Dès lors, ces
        cookies ne stockent aucune information nous permettant de vous identifier
        personnellement.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>• de0a99ansportal_session: Ce cookie est utilisé pour vous connecter. Il a une durée d'une heure avant expiration.</span></p>

    <p><span>• XSRF-TOKEN: Ce cookie permet la prévention de falsification de requête intersite. Il a une durée d'une heure avant expiration.</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>2.2. Les cookies de mesure d’audience</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Les cookies
        de mesure d’audience nous aident à établir des statistiques de fréquentation et
        d’utilisation des divers éléments composant notre Site (rubriques et contenus
        visités, parcours). Ils nous permettent d’en améliorer l’intérêt et
        l’ergonomie.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>•</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>&nbsp;</span></b></p>

    <p class="title">
      <b><span>2.3. Les cookies et boutons de réseaux sociaux</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Les cookies
        réseaux sociaux visent à permettre aux Utilisateurs d'utiliser les
        fonctionnalités des différentes plateformes et notamment de partager des pages
        et du contenu via ces réseaux sociaux tiers. Ils permettent également aux
        réseaux sociaux de cibler l'offre publicitaire qu'ils diffusent sur leurs
        plateformes.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>•</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>2.4. Les cookies de tiers</span></b>
    </p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>En ayant un
        compte sur le Site, vous pouvez bénéficier de Services mis à disposition par
        nos partenaires. Dans ce cadre, ces partenaires pourront être amenée à
        collecter des cookies. Pour en savoir plus sur les cookies collectés par ces
        partenaires et gérer vos préférences, veuillez consulter leurs politiques de confidentialité.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>3. COMMENT GÉRER VOS COOKIES ?</span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Concernant
        le dépôt des cookies strictement nécessaires au fonctionnement de notre Site,
        il est activé par défaut et ne requiert pas de consentement de votre part. La
        durée de vie de ces cookies est limitée dans le temps à 13 mois, et elle n’est
        pas prorogée automatiquement lors de nouvelles visites.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Les autres
        cookies sont soumis à votre consentement. Vous pouvez donc consentir au dépôt
        de ces cookies, ou les refuser, et ce depuis le gestionnaire de cookies qui
        figure sur l’écran, disponible à tout moment lors de votre visite de notre
        Site. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Outre la
        gestion de votre consentement via notre gestionnaire de cookies, vous pouvez
        empêcher votre navigateur d’accepter certains cookies, notamment ceux déposés
        sur notre Site par l’un de nos partenaires ou tout autre tiers. A cette fin,
        vous pouvez paramétrer votre navigateur en suivant les instructions ci-dessous.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Internet Explorer :</span></b></p>

    <p>
      <span>• Dans Internet Explorer, cliquez sur le bouton « Outils », puis sur « Options Internet » ;</span>
    </p>

    <p>
      <span>• Sousl'onglet « Général », sous « Historique de navigation », cliquez sur « Paramètres » ;</span>
    </p>

    <p><span>• Cliquez sur le bouton « Afficher les fichiers » ;</span></p>

    <p>
      <span>• Cliquez sur
        l'en-tête de colonne « Nom » pour trier tous les fichiers dans l'ordre
        alphabétique, puis parcourez la liste jusqu'à ce que vous voyiez des fichiers
        commençant par le préfixe « Cookie » (tous les cookies possèdent ce préfixe et
        contiennent habituellement le nom du site Web qui a créé le cookie) ;</span>
    </p>

    <p><span>• Sélectionnez le ou les cookies comprenant le nom du Site et supprimez-les ;</span></p>

    <p>
      <span>• Fermez la
        fenêtre qui contient la liste des fichiers, puis cliquez deux fois sur « OK »
        pour retourner dans Internet Explorer.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Firefox :</span></b></p>

    <p><span>• Allez dans l'onglet « Outils » du navigateur puis sélectionnez le menu « Options » ;</span></p>

    <p>
      <span>• Dans la
        fenêtre qui s'affiche, choisissez « Vie privée » et cliquez sur « Affichez les
        cookies » ;</span>
    </p>

    <p><span>• Repérez les fichiers qui contiennent le nom du Site et cliquez sur « Effacer » ;</span></p>

    <p>
      <span>• Sélectionnez
        les cookies qui contiennent le nom des sites concernés et cliquez sur «
        Supprimer » ou sur « Tout supprimer ».</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Safari :</span></b></p>

    <p><span>• Dans votre navigateur, choisissez le menu « Édition Préférences » ;</span></p>

    <p><span>• Cliquez sur « Sécurité » ;</span></p>

    <p><span>• Cliquez sur « Afficher les cookies » ;</span></p>

    <p>
      <span>• Sélectionnez
        les cookies qui contiennent le nom du Site et cliquez sur « Effacer » ou sur «
        Tout effacer » ;</span>
    </p>

    <p><span>• Après avoir supprimé les cookies, cliquez sur « Terminé ».</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Google Chrome :</span></b></p>

    <p><span>• Dans la barre d'outils de votre navigateur, sélectionnez « Plus » ;</span></p>

    <p><span>•Sélectionnez « Paramètres » ;</span></p>

    <p><span>• En bas de la page, sélectionnez « Afficher les paramètres avancés » ;</span></p>

    <p><span>• Dans la section « Confidentialité », sélectionnez « Paramètres de contenu » ;</span></p>

    <p><span>• Sélectionnez « Interdire à tous les sites de stocker des données » ;</span></p>

    <p><span>•Sélectionnez « OK ».</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p class="title">
      <b><span>4. MODIFICATION DE LA PRÉSENTE POLITIQUE DE COOKIES</span></b>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        pouvons être amenés à modifier occasionnellement la présente politique, afin
        notamment de se conformer à toutes évolutions réglementaires,
        jurisprudentielles, éditoriales ou techniques. Le cas échéant, nous changerons
        la date de « dernière mise à jour » et indiquerons la date à laquelle les
        modifications ont été apportées. Lorsque cela est nécessaire, notamment mais pas
        exclusivement en cas de modification substantielle ou d’évènement particulier
        requérant la modification de la présente politique, nous vous informerons et/ou
        solliciterons votre accord. Nous vous conseillons de consulter régulièrement
        cette page pour prendre connaissance des éventuelles modifications ou mises à
        jour apportées à notre politique. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>5. CONTACT </span></b></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Pour toute
        question relative à la présente politique ou pour toute demande relative à vos
        Données, vous pouvez nous contacter en : </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>• Adressant un e-mail à l’adresse électronique suivante : contact@de0a99ans.com</span></p>

    <p><span>• Adressant un courrier à l’adresse postale suivante : </span></p>

    <p><span>&nbsp;</span></p>

    <p><span>CLASSIP</span></p>
    <p><span>Immeuble EUROPA, Bâtiment A</span></p>
    <p><span>310 avenue de l’Europe</span></p>
    <p><span>44240 SUCÉ SUR ERDRE</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>
  </section>

  <FooterComponent />
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'PrivacyPolicy',
  components: { FooterComponent },
};
</script>
<style scoped>
.container {
  width: 75%;
  margin-top: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #052f5f;
  font-weight: bold;
  font-size: 16px;
}

.discover-title {
  font-size: 32px;
  color: #052f5f;
  font-weight: 700;
  text-align: center;
  max-width: 500px;
  min-width:300px;
}

p {
  color: #052f5f;
}

span {
  color: #052f5f;
}

a {
  color: #2DBEFF;
}
</style>
<style scoped>
.container {
  width: 75%;
  margin-top: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #052f5f;
  font-weight: bold;
  font-size: 16px;
}

.discover-title {
  font-size: 32px;
  color: #052f5f;
  font-weight: 700;
  text-align: center;
  max-width: 500px;
  min-width:300px;
}

p {
  color: #052f5f;
}

span {
  color: #052f5f;
}

a {
  color: #2DBEFF;
}
</style>
