<template>
  <div class="container-decoration">
    <div
      class="decoration-square-1"
      :style="{ backgroundColor: firstColor }"
    />
    <div
      class="decoration-square-2"
      :style="{ backgroundColor: secondColor }"
    />
    <div
      class="decoration-square-3"
      :style="{ backgroundColor: firstColor }"
    />
    <div
      class="decoration-square-4"
      :style="{ backgroundColor: firstColor }"
    />
    <div
      class="decoration-square-5"
      :style="{ backgroundColor: secondColor }"
    />
  </div>
</template>

<script>
import { useAuthStore } from '../store/store.js';

export default {
  data() {
    return {
      firstColor: '#052f5f',
      secondColor: '#f8485e',
      logo: null,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    const authStore = useAuthStore();
    if (
      authStore.getUser
      && authStore.getUser.company
      && authStore.getUser.company.name
    ) {
      this.firstColor = authStore.getUser.company.color_1;
      this.secondColor = authStore.getUser.company.color_2;
    }
  },
};
</script>

<style>
.container-decoration {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.decoration-square-1 {
  width: 9.5rem;
  height: 9.5rem;
  left: 0rem;
}

.decoration-square-2 {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  top: 14rem;
  left: 13rem;
}

.decoration-square-3 {
  position: absolute;
  width: 8.125rem;
  height: 8.125rem;
  left: 34.5rem;
}

.decoration-square-4 {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  right: 15.5rem;
}

.decoration-square-5 {
  position: absolute;
  width: 9.5rem;
  height: 9.5rem;
  right: 0rem;
}

@media screen and (max-width: 1200px) {
  .decoration-square-3 {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .decoration-square-1,
  .decoration-square-5 {
    width: 6.25rem;
    height: 6.25rem;
  }

  .decoration-square-2 {
    width: 3.125rem;
    height: 3.125rem;
    top: 10rem;
    left: 6rem;
  }
  .decoration-square-4 {
    width: 2.2rem;
    height: 2.2rem;
    top: 15.25rem;
    right: 6.25rem;
  }
}
</style>
