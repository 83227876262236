<template>
  <HeaderComponent />
  <BannerComponent>
    <div
      class="form ml-2 flex-1"
    >
      <div class="mb-2 mt-2">
        <router-link
          to="/profil"
          class="links"
          :style="{ '--first-color': firstColor}"
        >
          Informations
        </router-link>
        <router-link
          to="/security"
          class="selected ml-1"
          :style="{ '--first-color': firstColor}"
        >
          Sécurité
        </router-link>
      </div>
      <div id="formSecurite">
        <div
          v-if="errorMessage"
          class="error-message mb-1 mt-2"
        >
          {{ errorMessage }}
        </div>
        <form @submit.prevent="updatePassword">
          <div class="profil-form-group">
            <label
              class="mt-2"
              for="currentPassword"
            >Mot de passe actuel*</label>
            <input
              id="currentPassword"
              v-model="currentPassword"
              type="password"
              placeholder="**********"
              required
            >
            <label
              class="mt-2"
              for="newPassword"
            >Nouveau mot de passe*</label>
            <input
              id="newPassword"
              v-model="newPassword"
              type="password"
              placeholder="**********"
              required
            >

            <label
              class="mt-2"
              for="confirmNewPassword"
            >Confirmez votre mot de passe*</label>
            <input
              id="confirmNewPassword"
              v-model="confirmNewPassword"
              type="password"
              placeholder="**********"
              required
            >
          </div>

          <button
            type="submit"
            class="mt-2 button-save p-0-5"
          >
            Sauvegarder
          </button>
          <br>
          <button
            class="mt-2 mb-2 delete-link text-center p-0-5"
            @click.prevent="showModal = true"
          >
            Supprimer mon compte
          </button>
        </form>
      </div>
    </div>
  </BannerComponent>
  <DeleteAccountModal
    v-if="showModal"
    @cancel="showModal = false"
    @confirm="deleteAccount"
  />

  <FooterComponent />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import { useAuthStore } from '../store/store.js';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import BannerComponent from '../components/BannerComponent.vue';
import DeleteAccountModal from '../components/Utils/DeleteAccountModal.vue';
import { updatePassword, deleteUser } from '../services/api.js';

export default {
  name: 'SecurityPage',
  components: {
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    DeleteAccountModal,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: '',
      showModal: false,
    };
  },
  mounted() {
    const authStore = useAuthStore();
    if (authStore.getUser && authStore.getUser.company && authStore.getUser.company.name) {
      this.firstColor = authStore.getUser.company.color_1;
      this.secondColor = authStore.getUser.company.color_2;
    }
  },
  methods: {
    validatePassword() {
      const { newPassword, confirmNewPassword } = this;
      if (newPassword.length < 8) {
        this.errorMessage = 'Le nouveau mot de passe doit contenir au moins 8 caractères.';
        return false;
      }

      if (newPassword !== confirmNewPassword) {
        this.errorMessage = 'Les mots de passe ne correspondent pas.';
        return false;
      }

      this.errorMessage = '';
      return true;
    },
    async updatePassword() {
      const toast = useToast();
      const { currentPassword, newPassword, confirmNewPassword } = this;

      if (!this.validatePassword()) {
        return;
      }

      const response = await updatePassword({
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmNewPassword,
      });
      if (response.status !== 200) {
        this.errorMessage = response.response.data.message;
      }
      if (response.status === 200) {
        toast.success('Mot de passe mis à jour avec succés !');
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
      }
    },
    async deleteAccount() {
      const toast = useToast();

      try {
        await deleteUser();
        toast.success('Suppression de compte avec succès', {
          position: 'top-left',
        });
        this.$router.push('/login');
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">

.error-message{
  color: red;
  font-weight: bold;
}

.button-save {
  width: 18rem;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #052f5f;
}

.button-save:hover{
  background-color: #052f5f;
  color: #fff;
}

.delete-link {
  width: 18rem;
  background-color: #fff;
  color: #DC3444;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #DC3444;
}

.delete-link:hover{
  background-color: #DC3444;
  color: #fff;
}

.profil-form-group{
  width: 40rem;
}

.profil-form-group label {
  display: block;
  font-weight: bold;
  color: #052f5f;
}
.profil-form-group input {
  width: 40%;
  height: 1.5rem;
  font-weight: bolder;
  border: none;
  color: #052f5f;
  border-bottom: 1px solid #052f5f;
}

.links {
  color: #052f5f;
  font-size: larger;
  font-weight: bold;
  opacity: 0.5;
  border-bottom: 0px solid;
  transition: border-bottom-width opacity 0.3s ease;
}

.links:hover {
  border-color: var(--first-color, #052f5f);
  border-bottom-width: 3px;
  transition: border-bottom-width 0.2s;
  opacity: 1;
  border-radius: 2px;
}

.selected {
  color: #052f5f;
  border-bottom: 3px solid var(--first-color, #052f5f);
  font-size: larger;
  font-weight: bold;
  border-radius: 2px;
}

.form{
  width: 15rem;
}

@media screen and (max-width: 1060px) {

.form{
  margin: auto;
}
}

</style>
