<template>
  <HeaderComponent />

  <BannerComponent>
    <div
      class="form ml-2 flex-1"
    >
      <div class="mb-2 mt-2">
        <router-link
          to="/profil"
          class="selected"
          :style="{ '--first-color': firstColor}"
        >
          Informations
        </router-link>
        <router-link

          to="/security"
          class="links ml-1"
          :style="{ '--first-color': firstColor}"
        >
          Sécurité
        </router-link>
      </div>
      <div id="formInformations">
        <form @submit.prevent="updateProfil">
          <div class="profil-form-group">
            <div
              v-if="isSuccess"
              class="confirmation-message mt-1"
            >
              Sauvegardé
            </div>
            <label
              class="mt-1"
              for="firstname"
            >Prénom*</label>
            <input
              id="firstname"
              v-model="firstname"
              type="text"
              required
            >
            <label
              class="mt-2"
              for="lastname"
            >Nom*</label>
            <input
              id="lastname"
              v-model="lastname"
              type="text"
              required
            >
            <label
              class="mt-2"
              for="email"
            >E-mail*</label>
            <input
              id="email"
              v-model="email"
              type="email"
              required
            >
            <small
              v-if="emailError"
              class="text-danger"
            ><br>{{ emailError }}</small>
            <label
              class="mt-2"
              for="phone"
            >Numéro de télephone portable*</label>
            <input
              id="phone"
              v-model="phone"
              type="tel"
              placeholder="exemple : 07 01 02 03 04"
              required
              :disabled="phone_verified_at"
            >
            <div v-if="!phone_verified_at">
              <button
                type="button"
                class="button-profil mt-1 p-0-5"
                @click="openVerificationPhoneModal"
              >
                Vérifier le téléphone
              </button>
            </div>
            <div v-else>
              <small style="margin-top: 0; color: rgb(193, 104, 104)">
                Numéro de téléphone vérifié le :
                {{ formatDate(phone_verified_at) }}.<br>
                Pour le changer, merci de contacter le support.
              </small>
            </div>
            <small
              v-if="phoneError"
              class="text-danger"
            ><br>{{ phoneError }}</small>
            <label
              class="mt-2"
              for="birthDay"
            >Date de Naissance</label>
            <input
              id="birthDay"
              v-model="birthday"
              type="date"
            >
            <label
              class="mt-2"
              for="adress"
            >Adresse</label>
            <input
              id="adress"
              v-model="address"
              type="text"
            >
          </div>
          <button
            class="mt-2 button-save p-0-5"
            type="submit"
          >
            Sauvegarder
          </button>
        </form>
      </div>
    </div>
  </BannerComponent>
  <PhoneVerificationModal
    v-if="showVerificationPhoneModal"
    @close-verification-phone-modal="showVerificationPhoneModal = false"
    @verification-phone-success="verifyPhoneSuccess"
  />
  <FooterComponent />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import PhoneVerificationModal from '../components/Utils/PhoneVerificationModal.vue';
import { useAuthStore } from '../store/store.js';
import BannerComponent from '../components/BannerComponent.vue';
import { sendSmsCodeForPhoneVerification } from '../services/api.js';

export default {
  name: 'ProfilPage',
  components: {
    HeaderComponent,
    FooterComponent,
    PhoneVerificationModal,
    BannerComponent,
  },
  data() {
    const authStore = useAuthStore();
    return {
      firstname: authStore.user.firstname,
      lastname: authStore.user.lastname,
      email: authStore.user.email,
      phone: authStore.user.phone,
      birthday: authStore.user.birthday,
      address: authStore.user.address,
      phone_verified_at: authStore.user.phone_verified_at,
      isSuccess: false,
      phoneError: '',
      showVerificationPhoneModal: false,
      emailError: '',
    };
  },
  mounted() {
    const authStore = useAuthStore();
    if (authStore.getUser && authStore.getUser.company && authStore.getUser.company.name) {
      this.firstColor = authStore.getUser.company.color_1;
      this.secondColor = authStore.getUser.company.color_2;
    }
  },
  methods: {
    async updateProfil() {
      const phoneRegex = /^(06|07)\d{8}$/;

      this.emailError = '';
      this.isSuccess = false;
      this.phoneError = '';

      if (!phoneRegex.test(this.phone)) {
        this.phoneError = 'Le numéro de téléphone doit commencer par 06 ou 07.';
        return;
      }
      try {
        const authStore = useAuthStore();
        await authStore.updateProfil({
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          birthday: this.birthday,
          address: this.address,
        });
        this.isSuccess = true;
      } catch (error) {
        if (error.response.status === 422 && error.response.data.errors.email) {
          this.emailError = 'Cet email est déjà utilisé.';
        } else {
          console.error('Error updating profile:', error);
        }
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR').replace(/\//g, '-');
    },

    async openVerificationPhoneModal() {
      const toast = useToast();
      try {
        const response = await sendSmsCodeForPhoneVerification();
        if (response.data.success) {
          this.showVerificationPhoneModal = true;
        } else {
          toast.error(
            "Échec lors de l'envoi du SMS, veuillez contacter le support",
          );
        }
      } catch (error) {
        toast.error(
          "Échec lors de l'envoi du SMS, veuillez contacter le support",
        );
      }
    },
    verifyPhoneSuccess(phoneVerifiedAt) {
      const toast = useToast();
      toast.success('Téléphone vérifié avec succés !');
      const authStore = useAuthStore();
      authStore.setUserPhoneVerifiedAt(phoneVerifiedAt);
      this.phone_verified_at = phoneVerifiedAt;
    },
  },
};
</script>

<style scoped lang="scss">
.profil-form-group{
  width: 40rem;
}

.profil-form-group label {
  display: block;
  font-weight: bold;
  color: #052f5f;
}
.profil-form-group input {
  width: 40%;
  height: 1.5rem;
  font-weight: bolder;
  border: none;
  color: #052f5f;
  border-bottom: 1px solid #052f5f;
}

.button-profil {
  width: 40%;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: solid 2px #052f5f;
}

.button-save {
  width: 16rem;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: solid 2px #052f5f;
}

button:hover {
  background-color: #052f5f;
  color: #fff;
}

.links {
  font-size: larger;
  font-weight: bold;
  opacity: 0.5;
  border-bottom: 0px solid;
  transition: border-bottom-width opacity 0.3s ease;
  color: #052f5f;
}

.links:hover {
  border-color: var(--first-color, #052f5f);
  border-bottom-width: 3px;
  transition: border-bottom-width 0.2s;
  opacity: 1;
  border-radius: 2px;

}

.selected {
  color: #052f5f;
  border-bottom: 3px solid var(--first-color, #052f5f);
  font-size: larger;
  font-weight: bold;
  border-radius: 2px;
}

.form{
  width: 15rem;
}

.links:hover::after {
  width: 100%;
}

.confirmation-message {
  background-color: lightgreen;
  color: darkgreen;
  width: 45%;
  height: 1.5rem;
  text-align: center;
  font-weight: bolder;
  padding-top: 0.3rem;
}

.text-danger {
  color: red;
  font-size: 0.8rem;
}

@media screen and (max-width: 1060px) {

  .form{
    margin: auto;
  }
}

</style>
