import * as Sentry from '@sentry/vue';
import { getUserInformation } from '../services/api.js';
import { useAuthStore } from '../store/store.js';

const refreshUserMiddleware = async () => {
  const authStore = useAuthStore();

  try {
    const userInfoResponse = await getUserInformation();
    authStore.user = userInfoResponse.data;
    localStorage.setItem('user', JSON.stringify(userInfoResponse.data));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default refreshUserMiddleware;
