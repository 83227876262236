<template>
  <div>
    <button
      v-if="!showForm"
      href="#"
      class="chat-button text-center"
      @click="toggleForm"
    >
      <i class="fas fa-comment" />
    </button>

    <form
      v-else
      class="form-contact"
      @submit.prevent="submitMessageSupport"
    >
      <div
        v-if="isSuccess"
        class="confirmation-message-contact mb-1"
      >
        Votre message a été envoyé avec succès !
      </div>
      <h3 class="text-color">
        Vous souhaitez écrire à notre support ?
      </h3>
      <textarea
        v-model="message"
        class="mt-1 mb-1 text-area"
        placeholder="Écrivez votre message"
        cols="27"
        rows="8"
        required
      />
      <small class="text-italic text-color"><i>Nous attirons votre attention sur la vigilance à apporter sur les informations saisies dans cette zone de texte libre. Seules les informations pertinentes et nécessaires pour le bon traitement de votre demande doivent être saisies.</i></small>
      <button
        class="button mt-1 mb-1 p-1"
        type="submit"
      >
        Envoyer
      </button>
      <button
        type="button"
        class="button p-1 "
        @click="toggleForm"
      >
        Fermer
      </button>
    </form>
  </div>
</template>

<script>

import { submitMessageToSupport } from '../../services/api.js';

export default {
  data() {
    return {
      showForm: false,
      message: '',
      isSuccess: false,
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      this.isSuccess = false;
    },
    submitForm() {
      this.message = '';
      this.isSuccess = true;
    },
    async submitMessageSupport() {
      try {
        await submitMessageToSupport({ message: this.message });
        this.submitForm();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>

.text-color{
  color: #052f5f;
}

.form-contact{
  position: fixed;
  padding: 1rem;
  bottom: 0rem;
  right: 0.5rem;
  background-color: #fff;
  color: 052f5f;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  max-width: 18.75rem;
  height: fit-content;
  border-radius: 5px;
}

.text-area{
  background-color: #f1f1f1;
  border: none;
}
.button {
  max-width: 370px;
  width:100%;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.button:hover{
  background-color: #052f5f;
  color: #fff;
}

.confirmation-message-contact {
  background-color: #DFF0D8;
  color: #458747;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  padding-bottom: 0.2rem;
}

</style>
