<template>
  <teleport to="body">
    <div class="modal-overlay">
      <div
        class="modal-content-container"
        @click.stop
      >
        <div
          class="progress-bar"
          style="margin-bottom: 30px; position: relative;"
        >
          <div
            class="progress"
            :style="{ width: ((currentQuestionIndexLocal + 1) / survey.questions.length) * 100 + '%' }"
          />
          <small
            class="text"
            style="position: absolute; right: 0; margin-top: 10px;"
          >
            {{ currentQuestionIndexLocal + 1 }} / {{ survey.questions.length }}
          </small>
        </div>
        <div class="modal-header">
          <h2>Pour obtenir votre code merci de répondre à ces quelques questions</h2>
          <p
            class="text"
            style="font-size: 14px"
          >
            Les résultats de ces questions sont uniquement à destination de votre entreprise et sont anonymisés.
          </p>
        </div>

        <!-- Contenu des questions -->
        <div class="modal-content">
          <p
            class="question-title"
            style="font-size: 14px; font-weight: 600;"
          >
            {{ currentQuestion.content }}
          </p>

          <!-- Unique Choice Question -->
          <div v-if="currentQuestion.type === 'unique_choice'">
            <div
              v-for="(option, index) in currentQuestion.options"
              :key="index"
              class="modal-option"
            >
              <input
                :id="'option-' + currentQuestion.id + '-' + index"
                v-model="selectedAnswer"
                type="radio"
                :name="'option-' + currentQuestion.id"
                :value="option.text"
                class="custom-radio"
              >
              <label :for="'option-' + currentQuestion.id + '-' + index">{{ option.text }}</label>
            </div>
            <p
              v-if="validationError"
              class="validation-error"
            >
              Veuillez sélectionner une option.
            </p>
          </div>

          <!-- Multiple Choice Question -->
          <div v-if="currentQuestion.type === 'multiple_choice'">
            <div
              v-for="(option, index) in currentQuestion.options"
              :key="index"
              class="modal-option"
            >
              <input
                :id="'option-' + currentQuestion.id + '-' + index"
                v-model="selectedMultipleAnswers"
                type="checkbox"
                :name="'option-' + currentQuestion.id"
                :value="option.text"
                class="custom-checkbox"
              >
              <label
                :for="'option-' + currentQuestion.id + '-' + index"
                style="font-size: 14px;"
              >{{ option.text }}</label>
            </div>
            <p
              v-if="validationError"
              class="validation-error"
            >
              Veuillez sélectionner au moins une option.
            </p>
          </div>

          <!-- Text Question -->
          <div v-if="currentQuestion.type === 'text'">
            <textarea
              v-model="selectedAnswer"
              class="modal-textarea"
            />
            <p
              v-if="validationError"
              class="validation-error"
            >
              Veuillez entrer une réponse.
            </p>
          </div>
        </div>

        <!-- Boutons de navigation -->
        <div class="modal-actions">
          <button
            class="button-cancel button-home-redirection p-1"
            @click="cancelSurvey"
          >
            Annuler
          </button>
          <button
            class="button-home-redirection p-1"
            @click="nextQuestion"
          >
            {{ isLastQuestion ? finalButtonText : 'Suivant' }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { submitSurveyResponse } from '../../services/api.js';

export default {
  props: {
    survey: {
      type: Object,
      required: true,
    },
    currentQuestionIndex: {
      type: Number,
      default: 0,
    },
    finalButtonText: {
      type: String,
      default: 'Valider et terminer',
    },
  },
  emits: ['survey-completed', 'cancel-survey', 'submit-answer'],
  data() {
    return {
      currentQuestionIndexLocal: this.currentQuestionIndex,
      selectedAnswer: '',
      selectedMultipleAnswers: [],
      validationError: false,
      requestIsPending: false,
    };
  },
  computed: {
    currentQuestion() {
      return this.survey.questions[this.currentQuestionIndexLocal];
    },
    isLastQuestion() {
      return this.currentQuestionIndexLocal === this.survey.questions.length - 1;
    },
  },
  methods: {
    validateAnswer() {
      this.validationError = this.currentQuestion.type === 'unique_choice' && !this.selectedAnswer;
      if (this.currentQuestion.type === 'multiple_choice' && this.selectedMultipleAnswers.length === 0) {
        this.validationError = true;
      }
      if (this.currentQuestion.type === 'text' && !this.selectedAnswer.trim()) {
        this.validationError = true;
      }
      return !this.validationError;
    },
    async nextQuestion() {
      if (this.validateAnswer() && !this.requestIsPending) {
        this.requestIsPending = true;

        const answer = this.currentQuestion.type === 'multiple_choice'
          ? this.selectedMultipleAnswers
          : this.selectedAnswer;

        try {
          await this.submitAnswer(answer);
          const toast = useToast();
          toast.success('Réponse enregistrée avec succès');

          if (!this.isLastQuestion) {
            this.currentQuestionIndexLocal += 1;
            this.resetAnswers();
          } else {
            this.$emit('survey-completed');
          }
        } catch (error) {
          const toast = useToast();
          toast.error('Erreur lors de la soumission de la réponse');
        } finally {
          this.requestIsPending = false;
        }
      }
    },
    resetAnswers() {
      this.selectedAnswer = '';
      this.selectedMultipleAnswers = [];
    },
    async submitAnswer(answer) {
      const formattedAnswer = Array.isArray(answer) ? answer : [answer];

      await submitSurveyResponse({
        survey_id: this.survey.id,
        question_id: this.currentQuestion.id,
        answer: formattedAnswer,
      });

      this.$emit('submit-answer', {
        question_id: this.currentQuestion.id,
        answer: formattedAnswer,
      });
    },
    cancelSurvey() {
      this.$emit('cancel-survey');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 18px;
  font-weight: bold;
  color: #052f5f;
}

.modal-content {
  text-align: left;
}

.question-title {
  margin-bottom: 15px;
}

.modal-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-option input {
  margin-right: 10px;
}

.validation-error {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-cancel {
  border: 1px solid #ff0000;
  color: #ff0000;
  font-weight: bold;
  cursor: pointer;
}

.button-cancel:hover {
  background-color: #ff0000;
  color: white;
}

.modal-textarea {
  width: 90%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.custom-radio:checked, .custom-checkbox:checked {
  accent-color: #052f5f;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #052f5f;
  border-radius: 5px;
}

</style>
