<template>
  <section class="container mx-auto">
    <h1><span class="discover-title">POLITIQUE DE COOKIES</span></h1>

    <p><span>Mise à jour le : 27/02/2022</span></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>CLASSIP (ci-après «CLASSIP» ou «nous») attache une grande
        importance à la protection des données personnelles (ci-après les
        «Données») des utilisateurs (ci-après les
        «Utilisateurs») de son site internet </span>
      <span>
        <a href="https://de0a99ans.com">
          https://de0a99ans.com
        </a>
      </span>
      <span> (ci-après
        le «Site»). La présente politique (ci-après la
        «Politique») a pour but de vous informer sur la manière dont nous
        collectons, en tant que responsable de traitement, vos Données par le biais de
        cookies présents sur notre Site et comment les paramétrer.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>1. QU’EST-CE QU’UN COOKIE ?</span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Un cookie
        est un petit fichier texte enregistré sur le terminal de votre navigateur
        (ordinateur, smartphone, tablette, etc.) lors de la visite du Site. Nous
        utilisons des cookies pour collecter des informations relatives à votre
        navigation, vos préférences et vos configurations, afin de vous adresser un
        contenu et des services personnalisés et de nous permettre d’analyser vos
        actions sur notre Site.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        utilisons un gestionnaire de cookies qui nous permet de mettre à votre
        disposition un moyen de récolter votre consentement sur notre Site. Ce
        gestionnaire permet aux Utilisateurs de visualiser l’ensemble des cookies que
        nous utilisons, et de consentir ou de refuser, à tout moment, tout ou partie
        des cookies utilisés lorsque ceux-ci ne sont pas nécessaires au fonctionnement
        du Site.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>En effet,
        certains cookies sont strictement nécessaires pour faire fonctionner notre
        Site. Du fait de leur caractère indispensable, ceux-ci sont exemptés du recueil
        de votre consentement. L’Utilisateur dispose de la possibilité d’accepter ou de
        refuser les autres cookies en utilisant le mécanisme intégré au gestionnaire de
        cookies, disponible à tout moment sur le bas de l’écran.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>2. QUELS TYPES DE COOKIES SONT COLLECTÉS ? </span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Nous
        utilisons des cookies pour collecter des informations sur les Utilisateurs de
        notre Site, afin de les mémoriser soit pendant la durée de votre visite
        (cookies dits “temporaires”) soit pour des visites répétées (cookies dits
        “persistants”).</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        utilisons plusieurs types de cookies sur notre Site, dont les caractéristiques
        sont décrites ci-dessous.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>2.1. Les cookies techniques strictement nécessaires</span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Ces cookies
        sont nécessaires au fonctionnement du Site. Ils permettent à l’Utilisateur d’en
        utiliser les principales fonctionnalités. Sans ces cookies, l’Utilisateur ne
        peut pas utiliser le Site normalement. Ils requièrent uniquement une
        information de l’Utilisateur pour être déposés sur son terminal et ne
        permettent aucun suivi comportemental ou d’identification. Dès lors, ces
        cookies ne stockent aucune information nous permettant de vous identifier
        personnellement.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>• de0a99ansportal_session: Ce cookie est utilisé pour vous connecter. Il a une durée d'une heure avant expiration.</span></p>

    <p><span>• XSRF-TOKEN: Ce cookie permet la prévention de falsification de requête intersite. Il a une durée d'une heure avant expiration.</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>2.2. Les cookies de mesure d’audience</span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Les cookies
        de mesure d’audience nous aident à établir des statistiques de fréquentation et
        d’utilisation des divers éléments composant notre Site (rubriques et contenus
        visités, parcours). Ils nous permettent d’en améliorer l’intérêt et
        l’ergonomie.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>•</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>&nbsp;</span></b></p>

    <p><b><span>2.3. Les cookies et boutons de réseaux sociaux</span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>Les cookies
        réseaux sociaux visent à permettre aux Utilisateurs d'utiliser les
        fonctionnalités des différentes plateformes et notamment de partager des pages
        et du contenu via ces réseaux sociaux tiers. Ils permettent également aux
        réseaux sociaux de cibler l'offre publicitaire qu'ils diffusent sur leurs
        plateformes.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>Nous utilisons les cookies suivants :</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>•</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>2.4. Les cookies de tiers</span></b></p>

    <p><b><span>&nbsp;</span></b></p>

    <p>
      <span>En ayant un
        compte sur le Site, vous pouvez bénéficier de Services mis à disposition par
        nos partenaires. Dans ce cadre, ces partenaires pourront être amenée à
        collecter des cookies. Pour en savoir plus sur les cookies collectés par ces
        partenaires et gérer vos préférences, veuillez consulter leurs politiques de confidentialité.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>3. COMMENT GÉRER VOS COOKIES ?</span></b></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Concernant
        le dépôt des cookies strictement nécessaires au fonctionnement de notre Site,
        il est activé par défaut et ne requiert pas de consentement de votre part. La
        durée de vie de ces cookies est limitée dans le temps à 13 mois, et elle n’est
        pas prorogée automatiquement lors de nouvelles visites.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Les autres
        cookies sont soumis à votre consentement. Vous pouvez donc consentir au dépôt
        de ces cookies, ou les refuser, et ce depuis le gestionnaire de cookies qui
        figure sur l’écran, disponible à tout moment lors de votre visite de notre
        Site. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Outre la
        gestion de votre consentement via notre gestionnaire de cookies, vous pouvez
        empêcher votre navigateur d’accepter certains cookies, notamment ceux déposés
        sur notre Site par l’un de nos partenaires ou tout autre tiers. A cette fin,
        vous pouvez paramétrer votre navigateur en suivant les instructions ci-dessous.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Internet Explorer :</span></b></p>

    <p>
      <span>• Dans Internet Explorer, cliquez sur le bouton « Outils », puis sur « Options Internet » ;</span>
    </p>

    <p>
      <span>• Sousl'onglet « Général », sous « Historique de navigation », cliquez sur « Paramètres » ;</span>
    </p>

    <p><span>• Cliquez sur le bouton « Afficher les fichiers » ;</span></p>

    <p>
      <span>• Cliquez sur
        l'en-tête de colonne « Nom » pour trier tous les fichiers dans l'ordre
        alphabétique, puis parcourez la liste jusqu'à ce que vous voyiez des fichiers
        commençant par le préfixe « Cookie » (tous les cookies possèdent ce préfixe et
        contiennent habituellement le nom du site Web qui a créé le cookie) ;</span>
    </p>

    <p><span>• Sélectionnez le ou les cookies comprenant le nom du Site et supprimez-les ;</span></p>

    <p>
      <span>• Fermez la
        fenêtre qui contient la liste des fichiers, puis cliquez deux fois sur « OK »
        pour retourner dans Internet Explorer.</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Firefox :</span></b></p>

    <p><span>• Allez dans l'onglet « Outils » du navigateur puis sélectionnez le menu « Options » ;</span></p>

    <p>
      <span>• Dans la
        fenêtre qui s'affiche, choisissez « Vie privée » et cliquez sur « Affichez les
        cookies » ;</span>
    </p>

    <p><span>• Repérez les fichiers qui contiennent le nom du Site et cliquez sur « Effacer » ;</span></p>

    <p>
      <span>• Sélectionnez
        les cookies qui contiennent le nom des sites concernés et cliquez sur «
        Supprimer » ou sur « Tout supprimer ».</span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Safari :</span></b></p>

    <p><span>• Dans votre navigateur, choisissez le menu « Édition Préférences » ;</span></p>

    <p><span>• Cliquez sur « Sécurité » ;</span></p>

    <p><span>• Cliquez sur « Afficher les cookies » ;</span></p>

    <p>
      <span>• Sélectionnez
        les cookies qui contiennent le nom du Site et cliquez sur « Effacer » ou sur «
        Tout effacer » ;</span>
    </p>

    <p><span>• Après avoir supprimé les cookies, cliquez sur « Terminé ».</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>Si vous utilisez le navigateur Google Chrome :</span></b></p>

    <p><span>• Dans la barre d'outils de votre navigateur, sélectionnez « Plus » ;</span></p>

    <p><span>•Sélectionnez « Paramètres » ;</span></p>

    <p><span>• En bas de la page, sélectionnez « Afficher les paramètres avancés » ;</span></p>

    <p><span>• Dans la section « Confidentialité », sélectionnez « Paramètres de contenu » ;</span></p>

    <p><span>• Sélectionnez « Interdire à tous les sites de stocker des données » ;</span></p>

    <p><span>•Sélectionnez « OK ».</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>4. MODIFICATION DE LA PRÉSENTE POLITIQUE DE COOKIES</span></b></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Nous
        pouvons être amenés à modifier occasionnellement la présente politique, afin
        notamment de se conformer à toutes évolutions réglementaires,
        jurisprudentielles, éditoriales ou techniques. Le cas échéant, nous changerons
        la date de « dernière mise à jour » et indiquerons la date à laquelle les
        modifications ont été apportées. Lorsque cela est nécessaire, notamment mais pas
        exclusivement en cas de modification substantielle ou d’évènement particulier
        requérant la modification de la présente politique, nous vous informerons et/ou
        solliciterons votre accord. Nous vous conseillons de consulter régulièrement
        cette page pour prendre connaissance des éventuelles modifications ou mises à
        jour apportées à notre politique. </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>

    <p><b><span>5. CONTACT </span></b></p>

    <p><span>&nbsp;</span></p>

    <p>
      <span>Pour toute
        question relative à la présente politique ou pour toute demande relative à vos
        Données, vous pouvez nous contacter en : </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><span>• Adressant un e-mail à l’adresse électronique suivante : contact@de0a99ans.com</span></p>

    <p><span>• Adressant un courrier à l’adresse postale suivante : </span></p>

    <p><span>&nbsp;</span></p>

    <p><span>CLASSIP</span></p>
    <p><span>Immeuble EUROPA, Bâtiment A</span></p>
    <p><span>310 avenue de l’Europe</span></p>
    <p><span>44240 SUCÉ SUR ERDRE</span></p>

    <p><span>&nbsp;</span></p>

    <p><span>&nbsp;</span></p>
  </section>

  <FooterComponent />
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'CookiesPolicy',
  components: { FooterComponent },
};
</script>

<style scoped>
.container {
  width: 75%;
  margin-top: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #052f5f;
  font-weight: bold;
  font-size: 16px;
}

.discover-title {
  font-size: 32px;
  color: #052f5f;
  font-weight: 700;
  text-align: center;
  max-width: 500px;
  min-width:300px;
}

p {
  color: #052f5f;
}

span {
  color: #052f5f;
}

a {
  color: #2DBEFF;
}
</style>
