<template>
  <div>
    <button
      v-if="service.status === 'MUST_REQUEST' && user.verification_status !== 'pending'"
      class="button-home-redirection p-1"
      @click="requestAccess"
    >
      Ça m’intéresse
    </button>
    <button
      v-else-if="service.status === 'PENDING_REQUEST' && user.verification_status !== 'pending'"
      class="button-home-redirection custom-border p-1"
      disabled
    >
      Demande d'accès effectuée
    </button>
    <button
      v-else-if="user.verification_status === 'pending'"
      class="button-home-redirection custom-border p-1"
      disabled
    >
      Votre compte est en cours de validation.
    </button>
  </div>
</template>

<script>
import { useAuthStore } from '../../../store/store.js';

export default {
  name: 'RequestAccessButton',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accessRequestMade: false,
    };
  },
  computed: {
    user() {
      return useAuthStore().getUser;
    },
  },
  watch: {
    'service.status'(newVal) {
      if (newVal === 'PENDING_REQUEST') {
        this.accessRequestMade = newVal;
      }
    },
  },
  mounted() {
    this.checkAccessRequest();
  },
  methods: {
    requestAccess() {
      this.$emit('open-request-access-modal', this.service.id);
    },
    checkAccessRequest() {
      if (this.service.status === 'PENDING_REQUEST') {
        this.accessRequestMade = true;
      }
    },
  },
};
</script>

<style scoped>
.custom-border {
  border: 2px solid #052f5f;
}
</style>
