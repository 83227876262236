import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', {
  state: () => ({
    isLoading: false,
    ignoreLoader: false,
  }),
  actions: {
    showLoader() {
      if (!this.ignoreLoader) {
        this.isLoading = true;
      }
    },
    hideLoader() {
      if (!this.ignoreLoader) {
        this.isLoading = false;
      }
    },
    enableIgnoreLoader() {
      this.ignoreLoader = true;
    },
    disableIgnoreLoader() {
      this.ignoreLoader = false;
    },
  },
});
