<template>
  <button
    class="button-home border ml-2 mt-1 p-1"
    :class="{ 'selected-button': isSelected }"
    @click.prevent="$emit('select', category.id)"
  >
    {{ category.label }}
  </button>
</template>

<script>
export default {
  props: {
    category: Object,
    isSelected: Boolean,
  },
};
</script>

<style scoped lang="scss">
.button-home {
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.selected-button {
  background-color: #052f5f;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
</style>
