<template>
  <div
    class="select-container"
    @click="toggleDropdown"
  >
    <div class="select-categories">
      <div
        v-if="selectedOption"
        class="selected-categorie"
      >
        <div
          v-if="selectedOption.pictogram"
          class="service-image"
        >
          <img :src="`${apiBaseMedia}${selectedOption.pictogram}`">
        </div>
        {{ selectedOption.label }}
      </div>
      {{ selectedLabel }}
      <div :class="['arrow', { rotate: isOpen }]" />
    </div>
    <div
      v-if="isOpen"
      class="options-container"
    >
      <div
        v-for="option in options"
        :key="option.id"
        class="option"
        @click="selectOption(option)"
        @click.stop="selectOption(option)"
      >
        <div
          v-if="option.pictogram"
          class="service-image"
        >
          <img :src="`${apiBaseMedia}${option.pictogram}`">
        </div>
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',

  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },

  emits: ['update:value'],
  data() {
    return {
      isOpen: false,
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
    };
  },

  computed: {
    selectedOption() {
      return this.options.find((option) => option.id === this.value);
    },
  },
  mounted() {
    if (this.options.length > 0 && !this.value) {
      this.$emit('update:value', this.options[0].id);
    }
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    selectOption(option) {
      this.$emit('update:value', option.id);
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.select-container {
  position: relative;
  cursor: pointer;
}

.select-categories {
  border: 2px solid #052f5f;
  font-weight: bold;
  color: #052f5f;
  width: 20rem;
  font-size: large;
  padding: 10px;
  background: #fff;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  border: solid #052f5f;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: translateY(-50%) rotate(45deg);
  transition: transform 0.3s;
}

.arrow.rotate {
  transform: translateY(-50%) rotate(-135deg);
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 2px solid #052f5f;
  background: #fff;
  z-index: 10;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: large;
  color: #052f5f;
  gap: 10px;
}

.option:hover {
  background-color: #f0f0f0;
}

.service-image {
  width: 30px;
}

.selected-categorie {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
