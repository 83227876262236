<template>
  <GenericModal
    :title="modalTitle"
    :content="modalContent"
    :text-cancel="textCancel"
    :text-confirm="textConfirm"
    @confirm="confirmRequest"
    @cancel="cancelRequest"
  />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import GenericModal from '../../Utils/GenericModal.vue';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { RequestServiceAccess } from '../../../services/api.js';

export default {
  name: 'RequestAccessModal',
  components: {
    GenericModal,
  },
  props: {
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      modalTitle: 'Vous souhaitez avoir accès à ce service ?',
      modalContent:
        "Notre équipe administrative validera votre demande dans les plus brefs délais (du lundi au vendredi de 9h à 17h). Une fois votre accès validé par notre équipe administrative, vous pourrez utiliser ce service librement ou demander votre code d'accès dans le cas d'un loisir numérique.",
      textConfirm: 'Oui, je confirme',
      textCancel: 'Annuler',
    };
  },
  methods: {
    async confirmRequest() {
      const accessRequestIsOk = await RequestServiceAccess(
        this.serviceId,
      );

      const toast = useToast();
      if (accessRequestIsOk.data) {
        toast.success('Accès au service activé avec succès !');
        this.$emit('access-request-validated');
        this.$emit('close-request-access-modal');
      } else {
        toast.error("Erreur lors de la demande d'accès.");
      }
    },
    cancelRequest() {
      this.$emit('close-request-access-modal');
    },
  },
};
</script>

<style scoped></style>
